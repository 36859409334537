$( document ).ready(function() {


    $('.modal').on('shown.bs.modal', function (e) {
        $(this).find('.select select').select2({
            allowClear: true,
            dropdownParent: $(this).find('.modal-content')
        });
    })

    $(".results__tbl-row").on('click', function(event){
        $(this).toggleClass('check');
    });

    $(".header__user-top").on('click', function(event){
        $(this).parent().toggleClass('open');
    });

    $(".header-mob__user").on('click', function(event){
        $(this).parent().toggleClass('open');
    });

    $(".pages__title").on('click', function(event){
        $(this).parent().toggleClass('open');
    });

    $(".toggle-password").click(function() {

        $(this).toggleClass("fa");
        var input = $($(this).attr("toggle"));
        if (input.attr("type") == "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });

    $( "#cl" ).datepicker({
        changeMonth: true,
        changeYear: true,
        showOtherMonths: true,
        selectOtherMonths: true,
        defaultDate: "+1w",
        numberOfMonths: 1,
        minDate: new Date($('#hiddendelivdate').val()),
        monthNames : ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
        dayNamesMin : ['Вс','Пн','Вт','Ср','Чт','Пт','Сб']
    });

    $( function() {
        var dateFormat = "mm/dd/yy",
            from = $( "#from" )
                .datepicker({
                    changeMonth: true,
                    changeYear: true,
                    showOtherMonths: true,
                    selectOtherMonths: true,
                    defaultDate: "+1w",
                    numberOfMonths: 1,
                    minDate: new Date($('#hiddendelivdate').val()),
                    monthNames : ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
                    dayNamesMin : ['Вс','Пн','Вт','Ср','Чт','Пт','Сб']
                })
                .on( "change", function() {
                    to.datepicker( "option", "minDate", getDate( this ) );
                }),
            to = $( "#to" ).datepicker({
                changeMonth: true,
                changeYear: true,
                showOtherMonths: true,
                selectOtherMonths: true,
                defaultDate: "+1w",
                numberOfMonths: 1,
                minDate: new Date($('#hiddendelivdate').val()),
                monthNames : ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'],
                dayNamesMin : ['Вс','Пн','Вт','Ср','Чт','Пт','Сб']
            })
                .on( "change", function() {
                    from.datepicker( "option", "maxDate", getDate( this ) );
                });

        function getDate( element ) {
            var date;
            try {
                date = $.datepicker.parseDate( dateFormat, element.value );
            } catch( error ) {
                date = null;
            }

            return date;
        }
    } );


    const dt = new DataTransfer(); // Permet de manipuler les fichiers de l'input file

    $("#attachment").on('change', function(e){
        for(var i = 0; i < this.files.length; i++){
            let fileBloc = $('<span/>', {class: 'file-block'}),
                fileName = $('<span/>', {class: 'name', text: this.files.item(i).name});
            fileBloc.append('<span class="file-delete"><span><svg><use xlink:href="#delete"></use></svg></span></span>')
                .append(fileName);
            $("#filesList > #files-names").append(fileBloc).parent().parent().parent().addClass('open');
        };
        // Ajout des fichiers dans l'objet DataTransfer
        for (let file of this.files) {
            dt.items.add(file);
        }
        // Mise à jour des fichiers de l'input file après ajout
        this.files = dt.files;

        // EventListener pour le bouton de suppression créé
        $('span.file-delete').click(function(){
            $(".file").removeClass('open');
            let name = $(this).next('span.name').text();
            // Supprimer l'affichage du nom de fichier
            $(this).parent().remove();
            for(let i = 0; i < dt.items.length; i++){
                // Correspondance du fichier et du nom
                if(name === dt.items[i].getAsFile().name){
                    // Suppression du fichier dans l'objet DataTransfer
                    dt.items.remove(i);
                    continue;
                }
            }
            // Mise à jour des fichiers de l'input file après suppression
            document.getElementById('attachment').files = dt.files;
        });
    });
});
